import { useEffect, useRef, useState } from 'react'
import { SelectBox } from 'devextreme-react/select-box';
import { ProductApiService } from '../../utils/productApiService';
import DateBox from 'devextreme-react/date-box';
import { formatDate_YYYYMMDD, qty_format } from '../../utils/common';
import { Button } from 'devextreme-react/button';
import LineChart from '../../components/Chart';
import { logOut } from '../../features/auth/authSlice';
import { useNavigate, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import SpeedoMeter from '../../components/SpeedoMeter';

function Dashboard() {
  const menuicon = useRef()
  const arrow = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch() 
  
  const handleLogout = () => {
    dispatch(logOut({ ...{ access: null }, user: null }))
    navigate('/')
  }

  const [show_modal, setShowModal] = useState(true)
  const [test, settest] = useState(0)


  const showmenu = (e) => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  }
  const show_subitems = (e) => {
    let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
    arrowParent.classList.toggle("showMenu");
  }

  const handleClose = () => setShowModal(false);
  return (
    <>
      <div className="sidebar close">
        <div className="logo-details">
          <i className="bx" ><img style={{ height: "30px" }} src="/assets/images/plusplus.png" alt="" /></i>
          <span className="logo_name">PlusPlus Research</span>
        </div>
        <ul className="nav-links">
        <li>
            <a href="/implied-volatility-graph">
              <i className="bx bx-line-chart"></i>
              <span className="link_name">Implied Volatility Graph</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="/implied-volatility-graph">Implied Volatility Graph</a></li>
            </ul>
          </li>
          <li>
            <a href="/dashboard">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="/dashboard">Dashboard</a></li>
            </ul>
          </li> 
        </ul>
      </div>
      <section className="home-section">
      <span className="bx home-content" style={{float:"right", marginRight:"20px",cursor:"pointer"}} onClick={(e)=> handleLogout()}>Logout</span>
        <div className="home-content">
          <i className="bx bx-menu" onClick={(e) => showmenu(e)}></i>
  
          
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="card-transparent">
                <div className="card-body" style={{textAlign: "center"}}>
                  <span>Test</span>
                  <SpeedoMeter value={test /100}/>
                </div>
                <input 
                  type="number" 
                  value={test} 
                  max={100}
                  min={0}
                  onChange={(e) => settest((e.target.value))} 
                  style={{ width: "100%", marginBottom: "10px", padding: "5px" }} 
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
      
          </div>
        </div>
      </section>  
    </>
  )
}

export default Dashboard